import { Client } from "../Prismic-Config";
import { RichText } from "prismic-reactjs";
import { useState, useEffect } from "react";
import "../pages/Home.css";

function Home() {
	const [doc, setDocData] = useState();

	const [skillList, setSkillList] = useState([]);
	const [dynamicSpan, setDynamicSpan] = useState("Javascript");
	const [showSkill, setShowSkill] = useState(false);
	let counter = 0;

	function changeSpan(response) {
		const document = response;
		let list = skillList;
		document.skills.forEach((skill) => {
			list.push(skill.lang);
		});

		setSkillList(list);
		setInterval(function () {
			if (counter < skillList.length - 1) {
				counter++;
			} else counter = 0;
			setDynamicSpan(skillList[counter]);
		}, 2000);
	}

	// Clear interval function
	function clear() {
		for (let i = 0; i < 100; i++) {
			window.clearInterval(i);
		}
	}

	useEffect(() => {
		const fetchData = async () => {
			const response = await Client.getSingle("home");
			if (response) {
				setDocData(response.data);
				changeSpan(response.data);
			} else {
				console.log("no response from CMS");
			}
		};
		fetchData();

		return () => {
			clear();
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="home-wrapper">
			<div
				className={`about-container ${
					showSkill ? "skill-inactive" : "skill-active"
				}`}
			>
				<h2 className="greeting">Hello,</h2>
				<h1 className="title">
					I am <span>Matt</span>
					<br />
					Web <span>Developer</span>
				</h1>

				{doc ? (
					<>
						<p className="about">
							{RichText.asText(doc["about-before"])}
							<span> {dynamicSpan}. </span>
							{RichText.asText(doc["about-after"])}
						</p>
						<button onClick={() => setShowSkill(true)}>What can I do?</button>
					</>
				) : (
					<div></div>
				)}
			</div>

			<div
				className={`skills-container ${
					showSkill ? "skill-active" : "skill-inactive"
				}`}
			>
				<h3>Skills</h3>
				{doc ? (
					doc.skills.map((i) => {
						return (
							<div key={i.lang} className="skill-row">
								<img alt={`${i.lang} logo`} src={i.langlogo.url} />
								<span key={i.level} style={{ width: `${i.level}%` }}></span>
							</div>
						);
					})
				) : (
					<div>Skills Bars</div>
				)}
				<button onClick={() => setShowSkill(false)}>Back</button>
			</div>
			<div className="profile-img">
				{doc ? <img alt="cover" src={doc.picture.url} /> : <div></div>}
			</div>
		</div>
	);
}

export default Home;
