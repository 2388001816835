import { NavLink } from "react-router-dom";
import "./Navbar.css";

function Navbar() {
	return (
		<>
			<nav className="navbar">
				<div className="menu-icon">
					<i className="fas fa-bars"></i>
				</div>
				<ul>
					<li>
						<NavLink
							exact
							to="/home"
							className="nav-link"
							activeClassName="nav-link-active"
						>
							Home
						</NavLink>
					</li>
					<li>
						<NavLink
							to="/projects"
							className="nav-link"
							activeClassName="nav-link-active"
						>
							Projects
						</NavLink>
					</li>
					<li>
						<NavLink
							to="/connect"
							className="nav-link"
							activeClassName="nav-link-active"
						>
							Connect
						</NavLink>
					</li>
				</ul>
			</nav>
		</>
	);
}

export default Navbar;
