import Prismic from "@prismicio/client";

const apiEndpoint = "https://iammatt.cdn.prismic.io/api/v2";
const accessToken =
	"MC5ZTkl6NEJJQUFDOEFhWlhN.77-977-9Yznvv70GKe-_vV4977-977-977-9ce-_vRcvZO-_vTxH77-977-977-977-977-9eu-_vU8nf1U";

export const linkResolver = (doc) => {
	if (doc.type === "page") return `/page/${doc.uid}`;
	return "/";
};

export const Client = Prismic.client(apiEndpoint, { accessToken });
