import "../components/Project.css";

function Project({ image, link }) {
	return (
		<div className="project-card">
			<a href={link}>
				<img src={image.url} alt={image.alt} />
			</a>
		</div>
	);
}

export default Project;
