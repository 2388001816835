import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from "react-router-dom";
import "./App.css";
import Projects from "./pages/Projects";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Connect from "./pages/Connect";
import BG from "./hero.jpg";

function App() {
	return (
		<div className="App" style={{ backgroundImage: `url(${BG})` }}>
			<div className="bg-filter">
				<Router>
					<Navbar />

					<Switch>
						<Redirect exact from="/" to="/home" />
						<Route exact path="/home" component={Home} />
						<Route exact path="/projects" component={Projects} />
						<Route exact path="/connect" component={Connect} />
					</Switch>
				</Router>
			</div>
		</div>
	);
}

export default App;
