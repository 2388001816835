import { Client } from "../Prismic-Config";
import { RichText } from "prismic-reactjs";
import { useState, useEffect } from "react";
import "../pages/Connect.css";

function Connect() {
	const [doc, setDocData] = useState();

	useEffect(() => {
		const fetchData = async () => {
			const response = await Client.getSingle("connect");
			if (response) {
				setDocData(response.data);
			} else {
				console.log("no response");
			}
		};
		fetchData();
	}, []);

	return (
		<div className="connect-wrapper">
			{doc ? (
				<div className="social-container">
					<h2 className="social-title">{doc.title[0].text}</h2>
					<h3 className="social-links">
						{RichText.asText(doc["link_description"])}
					</h3>
					<a href={doc.social_link.url}>
						<img
							src={doc.social_image.icon.url}
							alt={doc.social_image.icon.alt}
						/>
					</a>
					<h3 className="divider-title">or</h3>
					<h3 className="form-title">
						{RichText.asText(doc["form_description"])}
					</h3>
				</div>
			) : (
				<div>No Content</div>
			)}

			<div className="form-container">
				<form className="contact-form" name="contact" method="post">
					<input type="hidden" name="form-name" value="contact" />
					<input type="text" name="name" placeholder="Name" />
					<input type="email" name="email" placeholder="Email" />
					<textarea
						name="message"
						placeholder="What do you want to chat about?"
						rows="5"
					></textarea>
					<button type="submit">Send</button>
				</form>
			</div>
		</div>
	);
}

export default Connect;
