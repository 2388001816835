import { Client } from "../Prismic-Config";
import Prismic from "@prismicio/client";
import { useState, useEffect } from "react";
import Project from "../components/Project";
import "../pages/Projects.css";

function Projects() {
	const [doc, setDocData] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			const response = await Client.query(
				Prismic.Predicates.at("document.type", "project"),
				{ orderings: "[my.project.order]" }
			);
			if (response) {
				setDocData(response.results);
			}
		};
		fetchData();
	}, []);

	return (
		<div className="projects-wrapper">
			<div className="cards-container">
				<h2>
					See what Matt's
					<br />
					been up to lately<span>.</span>
				</h2>
				{doc ? (
					doc.map((project) => {
						return (
							<Project
								key={project.id}
								image={project.data.image}
								link={project.data.link.url}
							/>
						);
					})
				) : (
					<div></div>
				)}
			</div>
		</div>
	);
}

export default Projects;
